import React from 'react';

import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import { GET_SUSPENSION_CODES } from 'src/components/players/players-queries';
import Spinner from 'src/components/spinner/spinner';
import SuccessNotification from 'src/components/success-notification/success-notification';
import { H2 } from 'src/components/typography/typography';

import * as styles from './suspension-codes.module.less';

const SuspensionCodes = () => {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(GET_SUSPENSION_CODES, {
    fetchPolicy: 'no-cache',
    client: meshGatewayClient,
  });

  const cols = [
    {
      key: 'code',
      title: t('code'),
      getValue: (d) => d.code,
    },
    { key: 'points', title: t('points'), getValue: (d) => d.points },
    { key: 'shortDescription', title: t('short description'), getValue: (d) => d.shortDescription },
    { key: 'longDescription', title: t('long description'), getValue: (d) => d.longDescription },
    {
      key: 'edit',
      title: ' ',
      getValue: (d) => (
        <Button linkStyle onClick={() => navigate(`edit/${d?.code}`)}>
          {t('edit')}
        </Button>
      ),
    },
  ];

  return (
    <>
      <SuccessNotification atUrl={`/suspension/codes`} variant={'success'} hideAfterMs={3000} />

      <Grid container justifyContent="space-between" alignItems="center">
        <H2>{t('suspension codes upper')}</H2>
        <Button onClick={() => navigate('add')}>
          <Icon name="sm-add" className={styles.addIcon} /> {t('add code')}
        </Button>
      </Grid>

      <Panel>
        <Grid container>{loading ? <Spinner /> : <AdminTable data={data?.listSuspensionCodes} columns={cols} />}</Grid>
      </Panel>
    </>
  );
};

export default SuspensionCodes;
