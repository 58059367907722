// extracted by mini-css-extract-plugin
export var addIcon = "suspension-codes-module--add-icon--d547c";
export var bodyBase = "suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodyLarge = "suspension-codes-module--body-large--8b6e5 suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodyLargeBold = "suspension-codes-module--body-large-bold--6b171 suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodyRegular = "suspension-codes-module--body-regular--a0b53 suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodyRegularBold = "suspension-codes-module--body-regular-bold--0622c suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodySmall = "suspension-codes-module--body-small--5e026 suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var bodySmallBold = "suspension-codes-module--body-small-bold--7b0ea suspension-codes-module--body-base--64157 suspension-codes-module--site-font--ae541";
export var borderTop = "suspension-codes-module--border-top--d1f03";
export var breakWordContainer = "suspension-codes-module--break-word-container--3a4d8";
export var buttonIconBase = "suspension-codes-module--button-icon-base--cd73d";
export var clickLink = "suspension-codes-module--click-link--0a55d";
export var dropdownBase = "suspension-codes-module--dropdown-base--afd02";
export var dropdownSelectBase = "suspension-codes-module--dropdown-select-base--96a23 suspension-codes-module--text-input--d861d";
export var flexCol = "suspension-codes-module--flex-col--3e1c7";
export var formErrorMessage = "suspension-codes-module--form-error-message--d0739";
export var h3 = "suspension-codes-module--h3--3ed94";
export var h4 = "suspension-codes-module--h4--47f3e";
export var hoverLink = "suspension-codes-module--hover-link--8ea46";
export var hoverRow = "suspension-codes-module--hover-row--2da17";
export var membershipContainer = "suspension-codes-module--membership-container--001c3 suspension-codes-module--flex-col--3e1c7 suspension-codes-module--primary-border--19fef";
export var membershipHeader = "suspension-codes-module--membership-header--a2f05";
export var membershipHeading = "suspension-codes-module--membership-heading--2dd48";
export var membershipLabel = "suspension-codes-module--membership-label--c0b25";
export var moreFiltersBorderClass = "suspension-codes-module--more-filters-border-class--eb6ec";
export var pageBg = "suspension-codes-module--page-bg--bc9d9";
export var pointer = "suspension-codes-module--pointer--5aebc";
export var primaryBorder = "suspension-codes-module--primary-border--19fef";
export var shadowBoxLight = "suspension-codes-module--shadow-box-light--4387f";
export var siteFont = "suspension-codes-module--site-font--ae541";
export var slideDownAndFade = "suspension-codes-module--slideDownAndFade--76667";
export var slideLeftAndFade = "suspension-codes-module--slideLeftAndFade--63884";
export var slideRightAndFade = "suspension-codes-module--slideRightAndFade--0a3a2";
export var slideUpAndFade = "suspension-codes-module--slideUpAndFade--23f50";
export var statusDecoration = "suspension-codes-module--status-decoration--6f5c8";
export var textInput = "suspension-codes-module--text-input--d861d";
export var textInverted = "suspension-codes-module--text-inverted--79df2";
export var textMediumDark = "suspension-codes-module--text-medium-dark--3512b";
export var tooltipFont = "suspension-codes-module--tooltipFont--bd4c3";
export var unstyledButton = "suspension-codes-module--unstyled-button--798be";