import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import SuspensionCodes from 'src/components/suspension-codes/suspension-codes';

const SuspensionCodesPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Suspension codes" />
      <PrivateRoute>
        <SuspensionCodes />
      </PrivateRoute>
    </Layout>
  );
};

export default SuspensionCodesPage;
